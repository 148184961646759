<template>
  <div class="os-qr" :class="{ 'os-qr--ready': isReady }">
    <OsIcon
      v-show="isLoading"
      name="Loader"
      class="os-qr__loader text-gray-400"
    />
    <canvas ref="qrcanvas" :class="{ 'os-qr--loading': isLoading }" />
    <div class="os-qr__label text-base bold">Continue on your phone</div>
  </div>
</template>
<script lang="ts">
import * as QRCode from 'qrcode'
import { storeToRefs } from 'pinia'
import { IKioskModeCookie } from '~/types/cookies'
import { useFiltersStore } from '~/stores/filtersStore'
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'

export default {
  name: 'QRCode',

  setup() {
    const kioskModeCookie = useCookie<IKioskModeCookie | null>('kioskmode')
    const isReady = ref(false)
    const isLoading = ref(false)
    const { $bus } = useNuxtApp()
    const filtersStore = useFiltersStore()
    const financeCriteriaStore = useFinanceCriteriaStore()

    const { active } = storeToRefs(filtersStore)

    watch(active, (after, before) => {
      if (!isEqual(after, before)) {
        updateQRCode()
      }
    })

    const qrcanvas = ref<HTMLCanvasElement | any>()
    const currentURL = computed(() => {
      const url = new URL(window?.location?.href)

      url.searchParams.delete('Retailer')
      if (active.value.retailers[0]) {
        url.searchParams.set('RetailerId', String(active.value.retailers[0]))
      }
      url.searchParams.delete('kioskmode')
      url.searchParams.delete('kiosktype')
      url.searchParams.set('utm_source', 'kiosk')
      url.searchParams.set('utm_medium', 'qrcode')
      url.searchParams.set(
        'utm_content',
        kioskModeCookie.value?.KioskType || 'NOT SET'
      )
      url.searchParams.set('finance', financeCriteriaStore.toDeeplink)
      return url.href
    })

    const updateQRCode = () => {
      isLoading.value = true
      setTimeout(() => {
        QRCode.toCanvas(
          qrcanvas.value,
          currentURL.value,
          { width: 205 },
          function (error: any) {
            // eslint-disable-next-line no-console
            if (error) console.error(error)
            isReady.value = true
            isLoading.value = false
          }
        )
      }, 1000)
    }

    onBeforeUnmount(() => {
      $bus.off('qrcode:refresh')
    })

    onMounted(() => {
      updateQRCode()
      $bus.on('qrcode:refresh', updateQRCode)
    })
    return {
      qrcanvas,
      currentURL,
      isReady,
      isLoading
    }
  }
}
</script>
<style lang="scss" scoped>
.os-qr {
  position: absolute;
  z-index: 99;
  right: 50px;
  top: 250px;
  display: block;
  opacity: 0;
  background: white;
  color: $black--darkest;
  border-radius: rem(6);
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));

  &--ready {
    animation-name: fadeIn;
    animation-delay: 0.5s;
    animation-duration: 0.6s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  canvas {
    opacity: 1;
    width: 164px;
    height: 164px;
    background: white;
    transition: 0.3s all;
    &.os-qr--loading {
      transform: scale(0.95);
      opacity: 0;
    }
  }
  .os-qr__loader {
    position: absolute;
    left: calc(50% - 8px);
    top: calc(164px / 2 - 8px);
  }
  .os-qr__label {
    max-width: 200px;
    text-align: center;
    margin: auto;
    padding-bottom: rem(12);
  }
}
</style>
